// theme colours and tints
const colors = {
  highlight: `#D1FA90`, //g50
  primarybutton: `#FFAA97`, //r50
  secondarybutton: `#FFD4A0`, //o50

  m10: `#29032D`,
  m20: `#521C41`,
  m30: `#811D4D`,
  m40: `#D4417A`,
  m50: `#F188B1`,
  m60: `#FFE5ED`,

  r10: `#2D0317`,
  r20: `#69252F`,
  r30: `#AB2E38`,
  r40: `#F65353`,
  r50: `#FFAA97`,
  r60: `#FFE4E3`,

  o10: `#341600`,
  o20: `#843903`,
  o30: `#F07B25`,
  o40: `#FF9736`,
  o50: `#FFD4A0`,
  o60: `#FFEBD7`,

  y10: `#302501`,
  y20: `#997700`,
  y30: `#EEBA00`,
  y40: `#FFD130`,
  y50: `#FFF385`,
  y60: `#FFFBD4`,

  g10: `#152400`,
  g20: `#305300`,
  g30: `#59900A`,
  g40: `#85CF1C`,
  g50: `#D1FA90`,
  g60: `#F0FCE1`,

  b10: `#020428`,
  b20: `#1C2648`,
  b30: `#5179A1`,
  b40: `#87CEE8`,
  b50: `#C9EEFC`,
  b60: `#EFFBFF`,

  i10: `#05002D`,
  i20: `#0E0747`,
  i30: `#4638A7`,
  i40: `#6D7EF7`,
  i50: `#CADAFF`,
  i60: `#EEF8FF`,

  c00: `#000000`,
  c10: `#131622`,
  c20: `#171926`,
  c30: `#1D2130`,
  c40: `#393D4D`,
  c50: `#4F5361`,
  c60: `#676B79`,
  c70: `#898D9A`,
  c80: `#ABAEBB`,
  c90: `#C8CBD6`,
  c100: `#E2E3E9`,
  c110: `#EEEFF1`,
  c120: `#FAFAFA`,
  c130: `#FFFFFF`,
};

export default colors;
