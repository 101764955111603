import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Cardd from '../components/Card';
import HorizontalCard from '../components/HorizontalCard';
import WIP from './wip';

import styled from '@emotion/styled';
import colors from '../styles/colors';
import Resume_pdf from '../assets/JocelyneMurphyResume_Fall2021.pdf';

import {
  PageTitle,
  Subtitle1,
  Subtitle2,
  SubtitleSection,
  BlockQuote,
  Caption,
  P,
  A,
  Button,
  Container,
  CaseStudy,
  Hyperlink,
  HomePageDiv,
  HomePageSvg,
  ButtonContainer,
} from '../styles/global.js';
import fruitfly from '../assets/artphotos/ff.png';
import bpsmallpic from '../assets/projects/BlueprintHero.png';
import pillarsmallpic from '../assets/projects/PillarHero.png';
import fundrisesmallpic from '../assets/projects/FundriseHero.png';
import cfsmallpic from '../assets/projects/CFHero.png';

import firefighter from '../assets/projects/oldwebsite/tech/IMG_E0831.JPG';
import firefighterpdf from '../assets/projects/Firefighter.pdf';
import fundrisepdf from '../assets/casestudy/fundrise/fundrisevisualcasestudy.pdf';
import { ReactComponent as Avatar } from '../assets/homepage/avatar.svg';
import { ReactComponent as Laptop } from '../assets/homepage/laptop.svg';
import dimensions from '../styles/dimensions';
import bgSpace from '../assets/homepage/bg-space.png';

const BackgroundImage = styled(`div`)``;
const links = {
  blueprint: (
    <Hyperlink href="https://uwblueprint.org/" target="_blank">
      {` `}
      UW Blueprint
    </Hyperlink>
  ),
  auvik: (
    <Hyperlink href="https://www.auvik.com/" target="_blank">
      {` `}
      Auvik Networks
    </Hyperlink>
  ),
  svpteens: (
    <Hyperlink
      href="https://www.socialventurepartners.org/waterloo-region/news/reflecting-on-the-2019-20-year-for-svp-teens-a-true-step-forward/"
      target="_blank"
    >
      {` `}
      SVP Teens
    </Hyperlink>
  ),
  syde: (
    <Hyperlink href="http://ugradcalendar.uwaterloo.ca/page/ENG-Systems-Design-Engineering" target="_blank">
      {` `}
      Systems Design Engineering{` `}
    </Hyperlink>
  ),
  gmail: (
    <Hyperlink href="mailto:jocelynemurphy@gmail.com" target="_blank">
      {` jocelynemurphy@gmail.com`}
    </Hyperlink>
  ),
  linkedin: (
    <Hyperlink href="https://www.linkedin.com/in/jmurphyy/" target="_blank">
      Linkedin
    </Hyperlink>
  ),
  miovision: (
    <Hyperlink href="https://miovision.com/blog/smart-city-challenge-uses-data-to-make-streets-safer" target="_blank">
      {` `}
      Miovision{` `}
    </Hyperlink>
  ),
};

const content = {
  bio: `Hi, I'm Jocelyne! I'm a software developer, product designer, and
  `,
  blueprint: `Designing UW Blueprint's website to generate more interest in the organization among nonprofit partners and future members. `,
  fundrise: `Leveraging design to encourage the adoption of high performance fundraising and team management techniques to help large-scale fundraisers achieve their goals.`,
  cf: `Developing an open-source scheduling tool to coordinate the donors and volunteers of a local nonprofit that reallocates food waste from local businesses to charities in need.`,
};

const ProjContainer = styled(`div`)`
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 1000px) {
    display: flex;
    flex-direction: column;
  }
`;

const HomeDiv = styled(`div`)`
  display: absolute;
  flex-direction: column;
  margin-top: calc(35vw + 50px);
  text-align: center;
`;

const BackgroundImg = styled(`img`)`
  position: absolute;
  display: block;
  width: 120%;
  z-index: -1;
  top: -42vw;
  left: -15vw;
`;

function Home() {
  return (
    <div>
      <Header currentPage="Home"></Header>
      <BackgroundImg src={bgSpace} />
      <div style={{ display: `flex`, width: `100vw`, overflow: `hidden`, position: `relative` }}>
        <Avatar
          style={{
            position: `absolute`,
            display: `block`,
            zIndex: `-1`,
            top: `calc(39vw - 150px)`,
            left: `calc(40vw - 40px)`,
            width: `max(18vw, 150px)`,
          }}
        ></Avatar>
        <Laptop
          style={{
            position: `absolute`,
            display: `block`,
            zIndex: `-1`,
            top: `calc(43vw - 5px)`,
            left: `38vw`,
            width: `20vw`,
          }}
        />
        <div
          style={{
            // width: '90vw',
            // maxWidth: '2000px',
            padding: `10vw`,
            left: `50%`,
            top: `50%`,
          }}
        >
          <HomeDiv>
            <PageTitle>Welcome!</PageTitle>
            <P>
              {content.bio} {links.syde} student at the University of Waterloo. <br />
            </P>
            <P>
              Currently seeking software and product internships for Summer 2022. Previously developing at{` `}
              {links.miovision}, {links.auvik}, {links.blueprint}, and leading at {links.svpteens}.
            </P>
            <P>
              Feel free to reach out via {links.linkedin} or at {links.gmail}.
            </P>
            <ButtonContainer>
              <Button primary>
                <A href="/work">Projects</A>
              </Button>
              <Button>
                <A href={Resume_pdf} target="blank">
                  Resume
                </A>
                {` `}
              </Button>
            </ButtonContainer>
          </HomeDiv>
        </div>
        <div></div>
      </div>

      <div style={{ height: `10vh` }}></div>
      {/* <div style={{ padding: '10vw' }}>
        <HorizontalCard
          img={bpsmallpic}
          title="UW Blueprint Website Redesign"
          hreef="/projects/blueprint"
          description={content.blueprint}
        ></HorizontalCard>
        <HorizontalCard
          img={bpsmallpic}
          title="UW Blueprint Website Redesign"
          hreef="/projects/blueprint"
          description={content.blueprint}
        ></HorizontalCard>
        <HorizontalCard
          img={bpsmallpic}
          title="UW Blueprint Website Redesign"
          hreef="/projects/blueprint"
          description={content.blueprint}
        ></HorizontalCard>
      </div> */}

      <PageTitle style={{ padding: `3vh 10vw` }}>Featured Projects</PageTitle>

      <div id="projects" style={{ padding: `0vw 8vw` }}>
        <ProjContainer>
          <Cardd
            img={cfsmallpic}
            title="Community Fridge"
            // hreef="/projects/fundrise"
            hreef="https://github.com/uwblueprint/community-fridge-kw"
            description={content.cf}
            date="UW Blueprint | September 2021 - Present"
            t1="Typescript"
            t2="ReactJS"
            t3="Chakra UI"
            cta="Github > "
          ></Cardd>
          <Cardd
            img={bpsmallpic}
            title="UW Blueprint Website Redesign"
            hreef="/projects/blueprint"
            description={content.blueprint}
            date=" UW Blueprint |  January 2021 - May 2021"
            t3="Information Architecture"
            t2="User Interviews"
            t1="Visual Design"
            cta="Case Study > "
          ></Cardd>
          <Cardd
            img={firefighter}
            title="Firefighter Robot"
            description="Building an autonomous robot that navigates obstacle-ridden mazes to extinguish a hidden flame. "
            date=" Partner Project | September 2019 - January 2020 "
            hreef={firefighterpdf}
            style={{ margin: `-20px` }}
            t1="PCB Board Design"
            t2="Soldering & Wiring"
            t3="Multimeter Troubleshooting"
            cta="Details & PCB Board Designs > "
          ></Cardd>
        </ProjContainer>
      </div>
      <center>
        <Button style={{ margin: `50px` }}>
          <A href="/work">Check out more of my work!</A>
          {` `}
        </Button>
      </center>
    </div>
  );
}

export default Home;
