import React from 'react';
import Header from '../../components/Header';
import styled from '@emotion/styled';
import {
  PageTitle,
  Subtitle1,
  Subtitle2,
  SubtitleSection,
  BlockQuote,
  Caption,
  P,
  HeroDiv,
  CaseStudy,
  HeroImage,
  Footer,
} from '../../styles/global.js';
import about_image from '../../assets/projects/BlueprintHero.png';
import bpfullscreen from '../../assets/projects/BlueprintFS.png';
import bphighfive from '../../assets/projects/bphighfive.png';
import colors from '../../styles/colors';

//images
import userflow from '../../assets/casestudy/blueprint/userflow.png';
import userflowlegend from '../../assets/casestudy/blueprint/userflowlegend.png';
import aboutrough from '../../assets/casestudy/blueprint/aboutrough.png';
import aboutrough2 from '../../assets/casestudy/blueprint/aboutrough2.png';
import alumillu from '../../assets/casestudy/blueprint/alumillu.png';
import designsystem from '../../assets/casestudy/blueprint/designsystem.png';
import dev4goodinspo from '../../assets/casestudy/blueprint/dev4goodinspo.png';
import dev4goodinspo2 from '../../assets/casestudy/blueprint/dev4goodinspo2.png';
import gjoin from '../../assets/casestudy/blueprint/gjoin.png';
import gnonprofit from '../../assets/casestudy/blueprint/gnonprofits.png';
import gprojects from '../../assets/casestudy/blueprint/gprojects.png';
import gstudents from '../../assets/casestudy/blueprint/gstudents.png';
import htninspo from '../../assets/casestudy/blueprint/htninspo.png';
import htninspo2 from '../../assets/casestudy/blueprint/htninspo2.png';
import inspo1 from '../../assets/casestudy/blueprint/inspo1.png';
import inspo2 from '../../assets/casestudy/blueprint/inspo2.png';
import navalloptions from '../../assets/casestudy/blueprint/navalloptions.png';
import navcomparison from '../../assets/casestudy/blueprint/navcomparison.png';
import navfinal from '../../assets/casestudy/blueprint/navfinal.png';

import Nonprofithero from '../../assets/casestudy/blueprint/npohero.png';
import prevcharacters from '../../assets/casestudy/blueprint/prevcharacters.png';
import projectspop from '../../assets/casestudy/blueprint/projectspop.png';
import projinit from '../../assets/casestudy/blueprint/projinit.png';
import projrough from '../../assets/casestudy/blueprint/projrough.png';
import projrough2 from '../../assets/casestudy/blueprint/projrough2.png';
import roughillu from '../../assets/casestudy/blueprint/roughillu.png';
import legacyheader from '../../assets/casestudy/blueprint/legacyheader.png';
import legacyNonprofit from '../../assets/casestudy/blueprint/legacynpo.png';
import legacyproj from '../../assets/casestudy/blueprint/legacyproj.png';
import legacyportal from '../../assets/casestudy/blueprint/legacyportal.png';
import legacystu from '../../assets/casestudy/blueprint/legacystu.png';
import legacyjoin from '../../assets/casestudy/blueprint/legacyjoin.png';
import stuillu from '../../assets/casestudy/blueprint/stuillu.png';
import covermedium from '../../assets/casestudy/blueprint/covermedium.png';
import gdesktop from '../../assets/casestudy/blueprint/gdesktop.png';
import dance from '../../assets/casestudy/blueprint/dance.png';
import dog from '../../assets/casestudy/blueprint/dog.png';
import adanna from '../../assets/casestudy/blueprint/adanna.png';
import carly from '../../assets/casestudy/blueprint/carly.png';

const content = {
  role: `Product Designer`,
  timeline: `January 2021 - April 2021`,
  context: `UW Blueprint is a student organization at the University of Waterloo that builds pro-bono software solutions - websites, mobile applications, analysis tools - to amplify the impact of nonprofit organizations. Blueprint members have a deep-seated passion for social good and are committed to their projects. They are high achieving and community-oriented students with diverse experiences, skills, and a strong drive to learn. Since inception, 248 unique members have volunteered over 70,000 hours to build tech for social good in partnership with 22 nonprofit organizations.`,
  contextSummary: `UW Blueprint is a student organization that partners with nonprofits to build tech for social good.`,
  problem: `The previous UW Blueprint website had the potential to be a central source of information for various audiences (nonprofit organizations, students, sponsors, community members), and was falling short. When proposing a student-run project to an organization with limited resources, a significant amount of objection handling is necessary. Nonprofit organizations questioning the quality of our projects were met with single paragraph descriptions of our work, when behind the scenes experienced PMs, Designers, and Developers were delivering high value projects to nonprofit organizations who were more than willing to provide glowing testimonials. Furthermore, the website was static, plain, and underwhelming, and overall did not reflect the talent or the vibrant community of the team. By investing in a website redesign, UW Blueprint aimed to drive interest in the organization.`,
  problemSummary: `How might UW Blueprint utilize their website to generate more interest in the organization from nonprofit organizations, students, sponsors, and community members?`,
  solutionSummary: `Our team designed a website featuring
    a highly visual project portfolio to emphasize the quality of UW Blueprint's work,
    a dramatic increase in images and graphics to reflect the vibrant student community,
    a consistent design system to enforce our brand,
    successful elements of the legacy website (as determined through user interviews), and
    strategic information architecture to drive more applications from students and nonprofit organizations.`,
};

const BackgroundImage = styled(`div`)`
  width: 100vw;
  height: 400px;
  background: url(${bpfullscreen}) repeat #bddeff;
  background-size: cover;
  margin: 0;
  padding: 0;
`;

const styledth = styled(`th`)`
textAlign: 'left',
width: '50vw',
backgroundColor: colors.c110,
borderRadius: '4px',
paddingLeft: '10px',
font-weight: bold,
`;

const StyledImg = styled(`img`)`
  width: 100%;
  padding: 2vh 0vh;

  border-radius: '4px';
  border: 2px;
`;

const FlexImg = styled(`img`)`
  width: 100%;
  padding: 8vh 0vh;
  // margin-left: 20%;
  // margin-right: 20%;
`;

const ProjContainer = styled(`div`)`
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 600px) {
    display: flex;
    flex-direction: column-reverse;
    flex-
  }
`;

const ProjInner = styled(`div`)`
  width: 50%;
  padding: 0;

  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;

const ProjInnerText = styled(`div`)`
  width: 50%;
  margin: 2vw;
  padding: 1vw;

  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;

function Blueprint() {
  return (
    <div>
      <div margin="0" padding="0">
        <BackgroundImage>
          <Header></Header>
        </BackgroundImage>
        {/* <HeroDiv>
          <HeroImage src={about_image}></HeroImage>
        </HeroDiv> */}
        <CaseStudy>
          {/* <img src={Hero} alt="Hero Image" /> */}
          <PageTitle>UW Blueprint Website Redesign</PageTitle>
          <P>{`Role: ${content.role}`}</P>
          <P>{`Timeline: ${content.timeline}`}</P>
          <P>{`Team: 2 Designers (myself and Neha Javed), 7 Developers, and 1 Product Manager`}</P>
          <P>{`Client: UW Blueprint, a student organization that develops tech for social good`}</P>

          <SubtitleSection>Summary</SubtitleSection>

          <Subtitle1>The Client</Subtitle1>
          <P>{`${content.context}`}</P>
          <BlockQuote>{`${content.contextSummary}`}</BlockQuote>

          <Subtitle1>The Problem</Subtitle1>
          <P>{`${content.problem}`}</P>
          <BlockQuote>{`${content.problemSummary}`}</BlockQuote>

          <Subtitle1>The Solution</Subtitle1>
          {/* <P>{`${content.solution}`}</P> */}
          <BlockQuote>{`${content.solutionSummary}`}</BlockQuote>

          <div style={{ display: `flex`, flexDirection: `row`, width: `100%` }}>
            <table>
              <tbody>
                <img
                  src={gprojects}
                  style={{ maxWidth: `30%`, margin: `0.5vw`, borderRadius: `4px`, border: `2px solid ${colors.c100}` }}
                ></img>
                <img
                  src={gnonprofit}
                  style={{ maxWidth: `30%`, margin: `0.5vw`, borderRadius: `4px`, border: `2px solid ${colors.c100}` }}
                ></img>
                <img
                  src={gjoin}
                  style={{ maxWidth: `30%`, margin: `0.5vw`, borderRadius: `4px`, border: `2px solid ${colors.c100}` }}
                ></img>
              </tbody>
            </table>
          </div>

          <SubtitleSection>{`The Challenge`}</SubtitleSection>

          <Subtitle1>Competitor Analysis</Subtitle1>
          <P>
            {` `}
            Before diving into ideation, we wanted to determine how organizations with similar objectives presented
            themselves to their audiences. We began by analysing the strenghts and weaknesses of the current website, as
            the number one competition when designing is the existing solution.
          </P>

          <Subtitle2>UW Blueprint&apos;s Legacy Website</Subtitle2>
          <P>
            <table style={{ paddingLeft: `0vw`, width: `100%`, position: `relative` }}>
              <tbody>
                <th
                  style={{
                    textAlign: `left`,
                    width: `15vw`,
                    backgroundColor: colors.c110,
                    borderRadius: `4px`,
                    paddingLeft: `10px`,
                    paddingRight: `10px`,
                  }}
                >
                  Description
                </th>
                <td style={{ paddingLeft: `10px`, paddingRight: `10px` }}>
                  A simple, static website built in 2017 to represent the organization and collect applications from
                  students and nonprofit organizations
                </td>
              </tbody>
              <tbody>
                <th
                  style={{
                    textAlign: `left`,
                    width: `15vw`,
                    backgroundColor: colors.c110,
                    borderRadius: `4px`,
                    paddingLeft: `10px`,
                    paddingRight: `10px`,
                  }}
                >
                  Screenshots
                </th>
                <div style={{ marginLeft: `10px` }}>
                  <img
                    src={legacyNonprofit}
                    style={{
                      width: `100%`,
                      maxWidth: `300px`,
                      height: `auto`,
                      padding: `0px`,
                      borderRadius: `4px`,
                      margin: `2px`,
                      margin: `2px`,
                      border: `1px solid ${colors.c120}`,
                    }}
                  />
                  <img
                    src={legacyproj}
                    style={{
                      width: `100%`,
                      maxWidth: `300px`,
                      height: `auto`,
                      padding: `0px`,
                      borderRadius: `4px`,
                      margin: `2px`,
                      margin: `2px`,
                      border: `1px solid ${colors.c120}`,
                    }}
                  />
                  <img
                    src={legacyportal}
                    style={{
                      width: `100%`,
                      maxWidth: `300px`,
                      height: `auto`,
                      padding: `0px`,
                      borderRadius: `4px`,
                      margin: `2px`,
                      border: `1px solid ${colors.c120}`,
                    }}
                  />
                </div>
              </tbody>
              <tr>
                <th
                  style={{
                    textAlign: `left`,
                    width: `15vw`,
                    backgroundColor: colors.c110,
                    borderRadius: `4px`,
                    paddingLeft: `10px`,
                    paddingRight: `10px`,
                  }}
                >
                  Strengths
                </th>

                <td style={{ paddingLeft: `10px`, paddingRight: `10px` }}>
                  <ul>
                    <li> Portal for students to apply and for UW Blueprint members to review applications </li>
                    <li> Page showcasing headshots of the past and present members of the UW Blueprint team</li>
                    <li>
                      {` `}
                      Links to our other social media (e.g. Instagram, Medium) where UW Blueprint is represented more
                      accurately{` `}
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <th
                  style={{
                    textAlign: `left`,
                    width: `15vw`,
                    backgroundColor: colors.c110,
                    borderRadius: `4px`,
                    paddingLeft: `10px`,
                    paddingRight: `10px`,
                  }}
                >
                  Weaknesses
                </th>
                <ul>
                  <li> Projects are not showcased in a way that reflects their quality</li>
                  <li>
                    {` `}
                    There is not enough objection handling for nonprofit organizations, the website is very tailored to
                    students and as a result, only a few nonprofit organizations trickle in every year{` `}
                  </li>
                  <li>
                    {` The internal culture of the team is not reflected in the website, meaning our team is largely made
                    up of students who have learned of the club's culture through friends already on the team`}
                  </li>
                </ul>
              </tr>
            </table>
          </P>

          <Subtitle2>Hack the North</Subtitle2>
          <P>
            <table style={{ paddingLeft: `0vw`, width: `80vw`, width: `100%` }}>
              <tr>
                <th
                  style={{
                    textAlign: `left`,
                    width: `15vw`,
                    backgroundColor: colors.c110,
                    borderRadius: `4px`,
                    paddingLeft: `10px`,
                    paddingRight: `10px`,
                  }}
                >
                  Description
                </th>
                <td style={{ paddingLeft: `10px`, paddingRight: `10px` }}>
                  A single page website that exists to facilitate student registration and participation in a yearly
                  Hackathon, organized by students at the University of Waterloo.
                </td>
              </tr>
              <tr>
                <th
                  style={{
                    textAlign: `left`,
                    width: `15vw`,
                    backgroundColor: colors.c110,
                    borderRadius: `4px`,
                    paddingLeft: `10px`,
                    paddingRight: `10px`,
                  }}
                >
                  Screenshots
                </th>
                <div style={{ marginLeft: `10px` }}>
                  <img
                    src={htninspo2}
                    style={{
                      width: `100%`,
                      maxWidth: `300px`,
                      width: `20%`,
                      height: `auto`,
                      padding: `0px`,
                      borderRadius: `4px`,
                      margin: `2px`,
                    }}
                  />
                  <img
                    src={htninspo}
                    style={{
                      width: `100%`,
                      maxWidth: `300px`,
                      width: `50%`,
                      height: `auto`,
                      padding: `0px`,
                      borderRadius: `4px`,
                      margin: `2px`,
                    }}
                  />
                </div>
              </tr>
              <tr>
                <th
                  style={{
                    textAlign: `left`,
                    width: `15vw`,
                    backgroundColor: colors.c110,
                    borderRadius: `4px`,
                    paddingLeft: `10px`,
                    paddingRight: `10px`,
                  }}
                >
                  Strengths
                </th>
                <td style={{ paddingLeft: `10px`, paddingRight: `10px` }}>
                  <ul>
                    <li>
                      {` `}
                      Good credibility conveyed by highlighting the value they provide and the organizations they
                      partner with{` `}
                    </li>
                    <li> Highly visual and interactive website (including illustrations), very few blocks of text </li>
                    <li> Excellent quotations, testimonials, and images of real people (good community conveyed) </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <th
                  style={{
                    textAlign: `left`,
                    width: `15vw`,
                    backgroundColor: colors.c110,
                    borderRadius: `4px`,
                    paddingLeft: `10px`,
                    paddingRight: `10px`,
                  }}
                >
                  Weaknesses / Differences
                </th>
                <td style={{ paddingLeft: `10px`, paddingRight: `10px` }}>
                  <ul>
                    <li>
                      {` `}
                      Single page design. For a hackathon this is fine, but as a year round organization, UW Blueprint
                      has more objectives than driving student registration. Our needs and our audiences are different,
                      therefore the content we create must be more tailored towards non-technical commmunity members and
                      the leaders of nonprofit organizations{` `}
                    </li>
                    <li>
                      {` `}
                      Hack the North rebrands every year. While this is beneficial for them to create a distinct
                      identity and reignite student excitement each year, Blueprint reinventing it&apos;s brand entirely
                      would only be confusing to the audiences we serve{` `}
                    </li>
                  </ul>
                </td>
              </tr>
            </table>
          </P>

          <Subtitle2>Develop for Good</Subtitle2>
          <P>
            <table style={{ paddingLeft: `0vw`, width: `80vw`, width: `100%` }}>
              <tr>
                <th
                  style={{
                    textAlign: `left`,
                    width: `15vw`,
                    backgroundColor: colors.c110,
                    borderRadius: `4px`,
                    paddingLeft: `10px`,
                    paddingRight: `10px`,
                  }}
                >
                  Description
                </th>
                <td style={{ paddingLeft: `10px`, paddingRight: `10px` }}>
                  A fairly large website that introduces the Develop for Good Organization, showcases their projects,
                  and allows contributors and nonprofit organizations to apply to get involved.
                </td>
              </tr>
              <tr>
                <th
                  style={{
                    textAlign: `left`,
                    width: `15vw`,
                    backgroundColor: colors.c110,
                    borderRadius: `4px`,
                    paddingLeft: `10px`,
                    paddingRight: `10px`,
                  }}
                >
                  Screenshots
                </th>
                <div style={{ marginLeft: `10px` }}>
                  <img
                    src={dev4goodinspo}
                    style={{
                      width: `100%`,
                      maxWidth: `300px`,
                      height: `auto`,
                      padding: `0px`,
                      borderRadius: `4px`,
                      margin: `2px`,
                    }}
                  />
                  <img
                    src={dev4goodinspo2}
                    style={{
                      width: `100%`,
                      maxWidth: `300px`,
                      height: `auto`,
                      padding: `0px`,
                      borderRadius: `4px`,
                      margin: `2px`,
                    }}
                  />
                </div>
              </tr>
              <tr>
                <th
                  style={{
                    textAlign: `left`,
                    width: `15vw`,
                    backgroundColor: colors.c110,
                    borderRadius: `4px`,
                    paddingLeft: `10px`,
                    paddingRight: `10px`,
                  }}
                >
                  Strengths
                </th>
                <td style={{ paddingLeft: `10px`, paddingRight: `10px` }}>
                  <ul>
                    <li>
                      {` `}
                      Showcasing the impact they have and communicating their value to nonprofit organizations (hours
                      volunteered, dollars saved, etc.){` `}
                    </li>
                    <li>
                      Clear explanation of project timelines and how the student and nonprofit partnership is
                      orchestrated.
                    </li>
                  </ul>
                </td>
                {` `}
              </tr>
              <tr>
                <th
                  style={{
                    textAlign: `left`,
                    width: `15vw`,
                    backgroundColor: colors.c110,
                    borderRadius: `4px`,
                    paddingLeft: `10px`,
                    paddingRight: `10px`,
                  }}
                >
                  Weaknesses / Differences
                </th>
                <td style={{ paddingLeft: `10px`, paddingRight: `10px` }}>
                  <ul>
                    <li>{`They don't do a great job of showcasing the details of their existing projects`}</li>
                    <li>
                      {`They are a larger organization that is less focused on building an internal culture at one particular school. Because of this, their messaging is far more about the impact of their work and less about the student community`}
                    </li>
                  </ul>
                </td>
                {` `}
              </tr>
            </table>
          </P>

          <Subtitle1>Constraints</Subtitle1>
          <P>
            <ul>
              <li>
                <b>Timeline:</b> We were given a short timeline of 4 months to design and develop the website. To ensure
                Developers always had work to do, this meant Designers had even less time to scope the problem, do user
                research, and iterate upon designs while gathering feedback from key audiences. This taught us to make a
                distinction between the &quot;must haves&quot; and &quot;nice to haves&quot; pretty early on, and
                resulted in a lot of learning around optimizing design work when collaborating with Developers.
              </li>
              <li>
                <b>Existing Branding:</b> UW Blueprint has existed since 2017 and has several sister branches at other
                schools across North America. As such, existing colours, fonts and styles needed to be implemented in
                our design system in order to maintain brand consistency.{` `}
              </li>
              <li>
                <b>Content:</b> Website design is very content heavily. We did not have a content writer or centralized
                assets, which meant a significant portion of our work involved finding high quality images, locating
                existing writing, testimonials, and statistics, and generating content from scratch (while aligning with
                the existing brand, graphics and voice of the organization).
              </li>
              <li>
                <b>Junior Developers:</b> For many Developers on our team, this website was their first experience using
                React. Because of this, we had to ensure that our designs were not overly ambitious and that we were
                designing atomically, with simple components that could be reused and combined to serve various
                purposes.
              </li>
              <li>
                <b>Limited Budget:</b> As a student-run organization, UW Blueprint wanted to keep the budget for this
                website as low as possible. A lack of funds to pay for services resulted in technology constraints that
                influenced our designs.
              </li>
            </ul>
          </P>

          <SubtitleSection>{`user Research Findings`}</SubtitleSection>

          <Subtitle1>Users</Subtitle1>
          <P> Two primary and two secondary users were identified for the UW Blueprint Website.</P>
          <Subtitle2>Primary users:</Subtitle2>

          <P>
            <table style={{ paddingLeft: `0vw`, width: `100%` }}>
              <tbody>
                <th
                  style={{
                    textAlign: `left`,
                    backgroundColor: colors.c110,
                    borderRadius: `4px`,
                    paddingLeft: `10px`,
                    paddingRight: `10px`,
                    width: `40%`,
                  }}
                >
                  Students{` `}
                </th>
                <th
                  style={{
                    textAlign: `left`,
                    backgroundColor: colors.c110,
                    borderRadius: `4px`,
                    paddingLeft: `10px`,
                    paddingRight: `10px`,
                    width: `40%`,
                  }}
                >
                  Nonprofit Organizations
                </th>
              </tbody>
              <tbody>
                <td style={{ paddingLeft: `10px`, paddingRight: `10px` }}>
                  <li>
                    Blueprint Members: will use the website to <b>view applications & update members and projects</b>
                  </li>
                  <li>
                    Potential Applicants: will use the website as a{` `}
                    <b>official source of information, location and motivation to apply</b>
                  </li>
                </td>
                <td style={{ paddingLeft: `10px`, paddingRight: `10px` }}>
                  <li>
                    Existing Partners: will use the website to <b>contact us, refer other organizations</b>
                  </li>
                  <li>
                    Potential Applicants: will use the website as a place to{` `}
                    <b>
                      learn about our team, answer logistical questions, see our past work, learn official information,
                      contact us
                    </b>
                  </li>
                </td>
              </tbody>
            </table>
          </P>
          <Subtitle2>Secondary users:</Subtitle2>
          <P>
            <table style={{ paddingLeft: `0vw`, width: `100%` }}>
              <tbody>
                <th
                  style={{
                    textAlign: `left`,
                    backgroundColor: colors.c110,
                    borderRadius: `4px`,
                    paddingLeft: `10px`,
                    paddingRight: `10px`,
                    width: `40%`,
                  }}
                >
                  Sponsors
                </th>
                <th
                  style={{
                    textAlign: `left`,
                    backgroundColor: colors.c110,
                    borderRadius: `4px`,
                    paddingLeft: `10px`,
                    paddingRight: `10px`,
                    width: `40%`,
                  }}
                >
                  Partner Organizations
                </th>
              </tbody>
              <tbody>
                <td style={{ paddingLeft: `10px`, paddingRight: `10px` }}>
                  <ul>
                    <li>
                      Learn about our <b>mission, vision, and impact</b>
                    </li>
                  </ul>
                </td>
                <td style={{ paddingLeft: `10px`, paddingRight: `10px` }}>
                  <ul>
                    <li>
                      Investigate <b>potential partnership opportunties, learn about our work</b>
                    </li>
                  </ul>
                </td>
              </tbody>
            </table>
          </P>
          <Subtitle1>Painpoints & Personas</Subtitle1>
          <P>
            After conducting user interviews with 2 nonprofit leaders and 6 students, we gained some key insights about
            our users, what their mindsets surrounding UW Blueprint are, and how their experiences with UW Blueprint
            differed from what they were expecting. These user personas were developed to continually remind us as
            Designers to design for our users and not for ourselves.
          </P>
          <ProjContainer>
            <ProjInner style={{ marginRight: `2vw` }}>
              <StyledImg src={carly} style={{ borderRadius: `4px`, height: `auto` }}></StyledImg>
            </ProjInner>
            <ProjInner style={{ marginLeft: `2vw` }}>
              <StyledImg src={adanna} style={{ borderRadius: `4px`, height: `auto` }}></StyledImg>
            </ProjInner>
          </ProjContainer>

          <Subtitle2>Additional Key Insights </Subtitle2>
          <P>
            <ul>
              <li>
                {`The number one way students and nonprofit organizations hear of UW Blueprint is through word of mouth`}
              </li>

              <li>
                {` Nonprofit organizations rarely have technical staff and may not be able to support a project after
              completion if there are bugs`}
              </li>
              <li>{`Testimonials are highly convincing`} </li>
              <li>
                {` UW Blueprint was misusing some language (e.g. "welfare" has specific connotations beyond doing good) specific to
              the charitable sector`}
              </li>
              <li>
                {` The navigation bar is unintuitive, students and nonprofit organizations alike aren't met with what they expect when they click on various headings`}
                {` `}
              </li>
            </ul>
          </P>

          <Subtitle1>Requirements</Subtitle1>
          <P>
            <ol>
              <li>
                <b>{`Landing Page:`}</b>
                {` `}
                {`A page intended to encapsulate UW Blueprint's identity and convince website
                visitors to read on. Oriented towards nonprofit organizations in particular, as they are the primary users that UW
                Blueprint struggles most to connect with`}
              </li>
              <li>
                <b>Application Review Portal: </b> A portal where candidates apply and where UW Blueprint members can
                sign in with their email addresses, view all student and nonprofit applications, and export them as CSVs
              </li>
              <li>
                <b>{`"Join Our Team" Page:`} </b> A page to convince students to apply and connect them to the
                application portal
              </li>
              <li>
                <b>{`"For Nonprofits" Page:`}</b> A page to convince nonprofit organizations to take the first step
                toward becoming a UW Blueprint project
              </li>
              <li>
                <b>Projects Page: </b> A portfolio to display the quality of our best work to all interested audiences,
                with a special emphasis on nonprofits and students
              </li>
              <li>
                <b>{`Team & Alumni Pages: `}</b> Pages to display past and present students who have volunteered their
                time with UW Blueprint, easy updating for internal members is a priority
              </li>
              <li>
                <b>{`About Us Page:`}</b> A page to provide a general overview of the organization to nonprofit
                organizations, sponsors, community members, and students
              </li>
            </ol>
          </P>

          <SubtitleSection>{`Solution`}</SubtitleSection>
          <Subtitle1>User Flow Diagram</Subtitle1>
          <P>
            {`Based on the specified requirements and our objective of increasing nonprofit and student applications, we constructed
            a user flow diagram that strategically guides our audiences towards applying. There were some slight iterations due to the needs of the client (e.g. the design of a Community page was a lower priority and will occur should we ever have content specifically geared towards sponsors and community members). After conducting user interviews, we also decided to link both the "Join Our Team!" page and "For Nonprofits" page directly in the navigation bar so that they are always accessible for those wishing to apply. `}
          </P>
          <StyledImg src={userflowlegend} style={{ width: `30%`, marginBottom: 0, padding: `2vw` }}></StyledImg>
          <StyledImg src={userflow} style={{ marginTop: `0px`, paddingTop: `1vw` }}></StyledImg>

          <SubtitleSection>Highlighted Feature Breakdown & Design Decisions</SubtitleSection>
          <Subtitle1>Navigation Bar</Subtitle1>
          <P>
            {` `}
            {`The navigation bar was one of the major pain points expressed by all users of the legacy website. Many users expressed confusion about what content was behind what heading (e.g. What's the difference between the Students and Team page? What's the difference between the Projects and Nonprofits page? Is the Nonprofit page for nonprofit organizations or about nonprofit organizations?)`}
          </P>
          <StyledImg src={legacyheader}></StyledImg>
          <Caption style={{ paddingTop: `10px`, margin: 0, paddingBottom: `10px` }}> Legacy navigation bar</Caption>
          <P>{` To create an intuitive navigation bar, we employed card sorting techniques when conducting user interviews with students who had no familiarity with the website. We presented them with every heading name option we had imagined, and asked them to tell us which order they would click on the headings when first arriving on the website. With each selection, we asked them to provide a brief description of what they assumed would be on the other side of each heading. These open-ended interviews allowed us to detect common trends in which content users expected to find behind which headings, allowing us to rename headings in an intuitive way. `}</P>
          <StyledImg
            src={navalloptions}
            style={{ borderRadius: `4px`, border: `2px solid ${colors.c110}` }}
          ></StyledImg>
          <Caption style={{ paddingTop: `10px`, margin: 0, paddingBottom: `10px` }}>
            {` `}
            Navigation bar with all options
          </Caption>

          <P>
            {` Based on our interviews, we decided on the following headings:`}
            <StyledImg src={navfinal} style={{ marginBottom: 0, paddingBottom: 0 }}></StyledImg>
            <Caption style={{ padding: 0, margin: 0 }}> Final navigation bar</Caption>

            <ul>
              <li>
                <b> About Us: </b>
                {`This was a standard page that many interviewees clicked on 3rd or 4th to round out their understanding of the organization. We felt it was valuable for all audiences, and aligned with our design goal of creating familiar designs for our nonprofit and student audiences. `}
              </li>
              <li>
                <b> Projects: </b>
                {`This page was determined to be essential, as both nonprofit and student interviewees jumped fairly immediately into investigating our work. This was expected behaviour that reinforced the need to showcase our projects more effectively.`}
              </li>
              <li>
                <b> Students: </b>
                {`Unexpectedly, this was one of the most popular pages. Interviewees expected to find a list of team members (content previously located under the Team page), so we updated the content corresponding to the Students header to match their expectations. This was not anticipated to be a high value page, but our research concluded that keeping it would provide value to our users.`}
              </li>
              <li>
                <b> Contact: </b>
                {`Interviewees rarely clicked on the Contact page, but with their feedback and the design principle of creating familiar designs, we decided to keep the Contact page in a predictable location. UW Blueprint is also open to partnerships with community organizations and sponsors, so wanted to ensure their contact information was accessible at all times.`}
              </li>
              <li>
                <b> Join Our Team: </b>
                {` `}
                {`Student interviewees concluded that this was where to go if they intended on applying, but also predicted correctly that it would have more information about the organization and the student experience. We decided against using the word "Apply", because while information on how to join the UW Blueprint team is valuable to the recruitment pipeline year-round, the application period is not always open.`}
              </li>
              <li>
                <b> For nonprofit organizations: </b>
                {` `}
                {`By adding the word "For" and by making this appear as a Button, our interviewees concluded that the page was the location to go if one was a nonprofit interested in partnering with us. We determined the term "Work with us" was too vague and didn't indicate the button was for nonprofit organizations, and we refrained from using the word "Apply", as that is not representative of UW Blueprint's process (the process is highly collaborative, with team members having calls to scope out problems and determine if a nonprofit project is a good fit) and may scare nonprofts away. `}
              </li>
            </ul>

            {`Other notes:`}
            <li>
              {`Very few people were interested in clicking on the Blog until they were actually in the interview stage and doing in depth research about UW Blueprint. Because of this, we removed it from the navigation bar.`}
            </li>
            <li>
              {`We briefly considered having an "Apply" drop down menu that branched into students and nonprofit organizations, however the processes are so different that we felt that term didn't satisfy the needs of our client or users.`}
            </li>
          </P>
          <Subtitle1>Projects Page</Subtitle1>
          <Subtitle2> Explorations and Iterations </Subtitle2>

          <ProjContainer>
            <ProjInner>
              <StyledImg src={legacyproj} style={{ borderRadius: `4px`, height: `auto` }}></StyledImg>
            </ProjInner>
            <ProjInnerText>
              <Subtitle2>Legacy Page</Subtitle2>
              <P>
                The existing projects page had no images, and each project was described using two paragraphs. Through
                our interviews we determined that UW Blueprint members were unsatisfied with how projects were being
                presented, nonprofit organizations were not overly convinced that we did quality work, and future
                applicants were looking for more information on the actual work done at UW Blueprint.
              </P>
            </ProjInnerText>
          </ProjContainer>
          <ProjContainer>
            <ProjInner>
              <StyledImg src={projrough2} style={{ borderRadius: `4px`, height: `auto` }}></StyledImg>
            </ProjInner>
            <ProjInnerText>
              <P>
                <Subtitle2>Wireframe: Content</Subtitle2>
                After assessing the conent provided by the client and the content that would provide the highest value
                to users of the website, we made the following changes to the Legacy projects Page:{` `}
                <ul>
                  <li>
                    {` `}
                    <b>Created collapsible project cards</b>
                    {` to make the content-heavy page easier to navigate`}
                  </li>
                  <li>
                    {` `}
                    <b>{`Maintained a distinction between current and past projects`}</b>
                  </li>
                  <li>
                    <b>Included more compelling information about projects,</b>
                    {` including photos of the designs, a clear description of the project and solution, nonprofit testimonials, a link to a more comprehensive case study, key takeaways, and in some cases demo videos.`}
                  </li>
                  <li>
                    {` `}
                    <b>Created a toggle for nonprofit / student views of each project,</b>
                    {`  with the intention of reorganizing content to best meet the needs of each website user (Note: this was not implemented in our final designs. Since we have users that don't fall into the student or nonprofit categories, we wanted to create a page that was universally appealing).`}
                  </li>
                </ul>
              </P>
            </ProjInnerText>
          </ProjContainer>
          <ProjContainer>
            <ProjInner>
              <StyledImg src={projinit} style={{ borderRadius: `4px`, height: `auto` }}></StyledImg>
            </ProjInner>
            <ProjInnerText>
              <Subtitle2>Wireframe: Page Structure</Subtitle2>

              <P>
                {` When ideating about the structure of the projects page, the following ideas were presented to the client:`}
                <ul>
                  <li>
                    <b>Highlighting our top projects in a carousel.</b>
                    {` `}
                    {`Through conversations with the client, some projects were determined to be significantly better than others. Since the primary objective of this page is get nonprofit organizations to trust the quality of our work, highlighting our best work is essential.`}
                  </li>
                  <li>
                    <b>Making the current projects section relatively small, </b>
                    {` as we have far less work to show off at the beginning of a project's life cycle (Note: the current projects descriptions were ultimately relocated to the Students page to give context to the work that current members are doing and because they don't add value in demonstrating the quality of our completed work).`}
                  </li>
                  <li>
                    <b>Filtering by project type at the bottom of the page.</b>
                    {` This feature was also ultimately scrapped, as the client decided that it was best to showcase our best work rather than allowing users to get an impression from older and less robust projects. While filtering is something that could be explored in the future, since the average user will not read every single case study it is important our best work is what users spend time exploring.`}
                  </li>
                </ul>
                {` `}
              </P>
            </ProjInnerText>
          </ProjContainer>
          <Subtitle2>Final Iteration</Subtitle2>
          <P>
            {`The final designs incorporated extensive feedback from our client. Key decisions include using
            modals instead of drop down menus, reducing the amount of text on the page, and only highlighting the very
            best work that UW Blueprint has completed (removing current projects and outdated projects as they don't add value when compared to finished and recent work). This page went through tons of iteration, and the end result is highly visual and easy
            to navigate with content that is tailored to our users (especially nonprofit organizations).`}
          </P>
          <ProjContainer>
            <ProjInner style={{ marginRight: `2vw` }}>
              <StyledImg src={gprojects} style={{ borderRadius: `4px`, height: `auto` }}></StyledImg>
            </ProjInner>
            <ProjInner style={{ marginLeft: `2vw` }}>
              <StyledImg src={projectspop} style={{ borderRadius: `4px`, height: `auto` }}></StyledImg>
            </ProjInner>
          </ProjContainer>

          <Subtitle1>Students Page</Subtitle1>
          <P>
            {`As users were mostly satisfied with this page, the changes made were not radical. However, slight improvements were added, including `}
            <b>condensing headshots into scrollable sections</b> (the previous page was incredibly long), adding{` `}
            <b>current project descriptions </b>
            {`(making the act of determining what a group of people called "Infra team" does less mysterious to people outside of the organization), and adding`}
            {` `}
            <b>Linkedin links </b>
            {` `}
            {`to each profile so that members of the team are more accessible to those interested in the UW Blueprint Organization.`}
          </P>
          <ProjContainer>
            <ProjInner style={{ marginRight: `2vw` }}>
              <StyledImg src={legacystu} style={{ borderRadius: `4px`, height: `auto` }}></StyledImg>
              <Caption>Legacy projects Page</Caption>
            </ProjInner>
            <ProjInner style={{ marginLeft: `2vw` }}>
              <StyledImg src={gstudents} style={{ borderRadius: `4px`, height: `auto` }}></StyledImg>
              <Caption>Revamped projects Page</Caption>
            </ProjInner>
          </ProjContainer>
          <Subtitle1>Join Our Team Page</Subtitle1>
          <P>
            {`As can be seen here, the previous page for students to learn how to join did very little to actually convince them that they wanted to join. Students were immediately greeted a wall of text and our hiring criteria. While this was informative, it did a terrible job at communicating the amazing community at UW Blueprint, and made the organization seem cold and competitive. The changes aim to`}
            {` `}
            <b>
              leverage graphics and friendly copy to make the application process more inviting to future candidates.
            </b>
          </P>
          <ProjContainer>
            <ProjInner style={{ marginRight: `2vw` }}>
              <StyledImg src={legacyjoin} style={{ borderRadius: `4px`, height: `auto` }}></StyledImg>
              <Caption>Legacy Student Recruitment Page</Caption>
            </ProjInner>
            <ProjInner style={{ marginLeft: `2vw` }}>
              <StyledImg src={gjoin} style={{ borderRadius: `4px`, height: `auto` }}></StyledImg>
              <Caption>{`New "Join Our Team!" Page`}</Caption>
            </ProjInner>
          </ProjContainer>

          <Subtitle1>For Nonprofits Page</Subtitle1>
          <P>
            {`Similar to the Students page, the Nonprofits page did little to convince nonprofit organizations they wanted to work with UW Blueprint. Unless a nonprofit was already 100% convinced they wanted to work with UW Blueprint, this page was not helping to increase our chances of engaging in a conversation with them. In the redesign, we incorporated learnings from our interviews with nonprofit leaders. `}
          </P>
          <Subtitle2>Section: What we can do for you</Subtitle2>
          <P>
            {`This section is heavily informed by our user research and addresses the following common nonprofit concerns: `}
            <ul>
              <li> Not knowing where they could use a software solution</li>
              <li> Insecurity about their use of technology, fear of being too far behind to catch up</li>
              <li> Concerns about adding another project to their workload </li>
              <li> Questioning the quality of student work </li>
              <li> Being unable to maintain or use the software once it is built </li>
            </ul>
          </P>

          <Subtitle2> Section: Our Process </Subtitle2>
          <P>
            {`The nonprofit leaders we interviewed loved this section of the website, as it allowed them to understand how long a project would take and what the process would look like.  `}
          </P>

          <Subtitle2> Section: Decision Criteria </Subtitle2>
          <P>
            {`Our user research also informed us that this section is important, as the limited time that nonprofit leaders have can be best spent if they have an idea of what UW Blueprint is looking for. If they are not a fit, they won't have to waste their valuable time engaging in the scoping process. `}
          </P>

          <Subtitle2>Other Features </Subtitle2>
          <P>
            {` This page uses the call to action "Contact Us", a word that carries less commitment and weight than "Apply". It also contains a Frequently Asked Questions section, several inviting graphics, and a variety of layouts to present the information in a way that's engaging.
`}
          </P>

          <ProjContainer>
            <ProjInner style={{ marginRight: `2vw` }}>
              <StyledImg src={legacyNonprofit} style={{ borderRadius: `4px`, height: `auto` }}></StyledImg>
              <Caption>Legacy nonprofit Recruitment Page</Caption>
            </ProjInner>
            <ProjInner style={{ marginLeft: `2vw` }}>
              <StyledImg src={gnonprofit} style={{ borderRadius: `4px`, height: `auto` }}></StyledImg>
              <Caption>{`New nonprofit Recruitment Page`}</Caption>
            </ProjInner>
          </ProjContainer>
          <Subtitle1>Branding and Visuals</Subtitle1>
          <P>
            UW Blueprint had a fairly strong existing brand to begin with on social media (the legacy website did not
            align with the fonts and colours being used elsewhere), but there were some key areas we wanted to improve
            for the whole organization throughout this website redesign.
          </P>
          <Subtitle2>Refining the design system and enforcing consistency</Subtitle2>
          <StyledImg src={designsystem}></StyledImg>
          <Subtitle2>Graphics that reflect diversity authentically</Subtitle2>
          <P>
            {` Previous Blueprint graphics had limited options in character design. This often resulted in characters that
            felt like stock images, where diversity only went as far as changing skin colours and putting the occasional
            character in a wheelchair. While there is always more work to be done, we tried to improve our character
            design by modelling characters after real life people by representing different religions, sexualities,
            hairstyles, fashion choices, body types, and races. Keeping the style of our characters consistent while
            expanding their diversity was challenging, but resulted in graphics that we hope will better represent the
            students and leaders that work with UW Blueprint and align with UW Blueprint's organization-wide goals of
            improving our diversity and inclusion practices.`}
          </P>
          <ProjContainer>
            <ProjInner style={{ marginRight: `2vw` }}>
              <StyledImg src={prevcharacters} style={{ borderRadius: `4px`, height: `auto`, width: `68%` }}></StyledImg>
              <StyledImg src={covermedium} style={{ borderRadius: `4px`, height: `auto`, width: `100%` }}></StyledImg>

              <Caption>Previous character variations & an example graphic</Caption>
            </ProjInner>
            <ProjInner style={{ marginLeft: `2vw` }}>
              <StyledImg src={stuillu} style={{ borderRadius: `4px`, height: `auto` }}></StyledImg>

              <StyledImg src={Nonprofithero} style={{ borderRadius: `4px`, height: `auto`, width: `90%` }}></StyledImg>
              <StyledImg src={alumillu} style={{ borderRadius: `4px`, width: `100%` }}></StyledImg>
              <Caption>A few examples of redesigned characters</Caption>
            </ProjInner>
          </ProjContainer>

          <Subtitle1>Out of Scope</Subtitle1>
          <P>
            {`Some excellent ideas surfaced when conducting user interviews that couldn't be implemented in the website but will be part of UW Blueprint's nonprofit recruitment strategy going forward:`}
            <ul>
              <li>{`Reaching out to local media and nonprofit newsletters to source more interest from nonprofit organizations and potential sponsors`}</li>
              <li>{`Throughout the project scoping process, reassuring nonprofit leaders that students are industry trained PMs, Product Designers, and Software Developers who will not overly burden them with the stress of another project`}</li>
              <li>{`Encouraging nonprofit leaders to refer other nonprofit organizations to us`}</li>
              <li>{`Establishing a UW Blueprint Foundation across several chapters of the organization, dedicated to providing long-term support to nonprofit organizations who don't have in-house technical staff and to maintain previously shipped projects`}</li>
            </ul>
          </P>

          <SubtitleSection>{`Results`}</SubtitleSection>
          <Subtitle1>Lessons Learned</Subtitle1>
          <P>
            <ul>
              <li>
                <b>How to collaborate effectively with Developers.</b> This was my first major project working on a
                cross-functional team, and I learned a lot about communicating with Developers! One big takeaway was
                that an agile development process (compared to waterfall) can be excellent with a short timeline, as
                development can begin before high fidelity designs are complete. Secondly, keeping Developers in the
                loop about the reasoning behind design decisions can give them useful context and help them make smart
                judgement calls. Finally, 5 minute clarifying conversations can save hours of labour and are essential
                for both parties to ensure that there is a shared understanding of constraints and objectives.
              </li>
              <li>
                <b> Designing regardless of content.</b> While designing, our client was unsure what content they wanted
                to put on each page. Initially this was preventing us from making progress, but we adapted by creating
                components that could be adapted to match different types and amounts of content. This was challenging,
                but was an important design lesson and ultimately motivated us to create a design system to keep our
                brand consistent!
              </li>
              <li>
                <b>
                  On a short timeline, PMs and Designers must collaborate to make clear distinctions between &quot;must
                  haves&quot; and &quot;nice to haves&quot;.
                </b>
                {` `}I was surprised by how much PM and Designer collaboration occured, but prioritizing which features
                to be built and in which order ended up being a large part of the Designer role.
              </li>
              <li>
                <b>Presenting distinct options to the client. </b>
                {` `}
                {`In initial feedback sessions, we would
                occasionally ask for ideas and lead brainstorming conversations before coming to the table with concrete designs to show. Since
                our client was made up of many students (all of whom seemed to have an opinion), we quickly realized
                that we needed to narrow the feedback we would be given in order to make progress. We began coming with
                clearly defined options to client meetings, and we were able to hone in on exactly what the client liked
                and didn't like far more easily.`}
              </li>
              <li>
                <b>Open-ended user interviews uncover brilliant ideas. </b>
                {` `}
                {`Going into user interviews with an open mind resulted in conversations outside of the scope of our website design goals. By making an effort to understand the user's needs wholistically, we picked up on key areas that the entire organization needed to concentrate on. Had we simply asked for website ideas, we would have done exactly our job, however by understanding where nonprofit organizations get their information and what the inner workings of their organizations look like, UW Blueprint is now exploring leveraging existing nonprofit communication channels and establishing a Blueprint Foundation to support the needs of past clients.`}
              </li>
            </ul>
          </P>

          <Subtitle1>Next Steps</Subtitle1>
          <P>
            <ul>
              <li>
                <b>App development</b> will be continuing over the next 4 months. This will involve completing the
                remaining pages based on designs, ensuring pages are responsive to various screen sizes (many website
                users will be on mobile devices), and animating the new illustrations to add personality to the site.
              </li>
              <li>
                <b>Follow up interviews with nonprofits </b>should be conducted to verify that their questions are being
                answered and they view Blueprint as a trustworthy organization to partner with. Extensive student
                feedback was conducted throughout the process, but the website could benefit from additional
                conversations with members of our other primary audience.
              </li>
              <li>
                <b>Reaching nonprofit organizations through their existing communication channels</b>
                {` `}
                {`is a key principle
                that must be considered in the context of UW Blueprint's marketing objectives. Recognizing that these channels as a part of a user's journey is essential to our design of the website, as the website is likely to be a secondary point of contact where users go to learn more about the organization. A next step to drive nonprofit interest would to begin marketing ourselves in exsitng channels
                (newsletters, newspapers, email lists, referrals, etc.), and using the new website as a primary source of information for all new audience members interested in partnering with UW Blueprint. `}
              </li>
            </ul>
          </P>
          <Subtitle1>Conclusion</Subtitle1>
          <P>
            {`This project supported an organization I'm really passionate about and I learned a ton about design
            along the way! I'm very excited to see the redesign launch later in 2021!`}
          </P>

          <img
            src={bphighfive}
            style={{ width: `60%`, padding: ` 8vh 0vh`, marginLeft: `20%`, marginRight: `20%` }}
          ></img>
        </CaseStudy>
      </div>
    </div>
  );
}

export default Blueprint;
