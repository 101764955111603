import { React, useState } from 'react';
import styled from '@emotion/styled';
import colors from '../styles/colors';
import { Hyperlink } from '../styles/global';
import { Box } from '@material-ui/core';
let darkMode = true;
if (document.URL.includes(`/work`) || document.URL.includes(`/about`) || document.URL.includes(`/art`)) {
  darkMode = false;
}

const navLinks = [
  { title: `Home`, path: `/` },
  { title: `Work`, path: `/work` },
  { title: `About`, path: `/about` },
  { title: `Art`, path: `/art` },
  { title: `Resume`, path: `./static/media/JocelyneMurphyResume_Fall2021.f0ac849d.pdf` }, // change once I add pdf viewer
];

const NavContainer = styled(`div`)`
  display: flex;
  flex-direction: row;
  font-weight: lighter;
  width: 98%;
  max-width: 1550px;
  margin: 0 auto;
  height: 1.5rem;
`;
const NavList = styled(`ul`)`
  justify-content: flex-end;
  margin-left: auto;
  font-size: medium;
  display: flex;
  flex-direction: row;
  margin-top: 0px;
  color: ${darkMode ? colors.c130 : colors.c10};

  @media screen and (max-width: 750px) {
    display: none;
  }
`;

const MobileNavList = styled(`ul`)`
  position: absolute;
  right: 0px;
  height: 40vh;
  background: ${darkMode ? colors.c00 : colors.c130};
  display: none;
  flex-direction: column;
  align-items: left;
  justify-content: space-around;
  margin-top:0px;
  width: 40vw;
  z-index: 1;
  padding-bottom: 30px;
  // transform: ${({ clicked }) => (clicked ? `translateX(0)` : `translateX(-100%)`)};

  @media screen and (max-width: 750px) {
    display: flex;
  }
}
`;

const NavItems = styled(`a`)`
  padding: 6px 15px;
  margin-top: 13px;
  font-family: 'Avenir', Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: ${darkMode ? colors.c130 : colors.c10};

  text-decoration: none;

  &:hover {
    color: ${colors.r40};
  }
`;

const NavName = styled(`p`)`
  font-family: 'Avenir', Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-weight: bold;
  font-size: large;
  padding: 0px 0px;

  text-decoration: none;
  color: ${darkMode ? colors.c130 : colors.c10};
  margin-right: auto;
  letter-spacing: 0.25em;
  margin-left: 15px;
`;

const Burg = styled(`div`)`
  z-index: 2;
  transform: translateX(100%);
  display: none;
  height: 20px;
  width: 20px;
  margin: 16px;
  @media screen and (max-width: 750px) {
    display: unset;
    opacity: 100%;
    transform: translateX(0%);
  }
`;

const Bun = styled(`div`)`
  background-color: ${darkMode ? colors.c130 : colors.c10};
  height: 3px;
  width: 100%;
  margin-top: 4px;

  @media screen and (max-width: 750px) {
    transform: translateX(0%);
  }
`;

const Header = (currentPage) => {
  const [clicked, setClicked] = useState(false);
  function handleClick() {
    setClicked(!clicked);
    // navLinks.forEach((link, index) => {
    //   link.style.animation = `navLinkFade 0.5s ease forwards ${index / 5 + 0.3}s`;
    // });
  }

  const NavListt = () => {
    return (
      <NavList>
        {navLinks.map(({ title, path }) => (
          <NavItems href={path} key={title}>
            {title}
          </NavItems>
        ))}
      </NavList>
    );
  };

  const MobileNavListt = () => {
    return (
      <MobileNavList>
        {navLinks.map(({ title, path }) => (
          <NavItems href={path} key={title}>
            {title}
          </NavItems>
        ))}
      </MobileNavList>
    );
  };

  const Burgg = () => {
    return (
      <Burg onClick={handleClick}>
        <Bun></Bun>
        <Bun></Bun>
        <Bun></Bun>
      </Burg>
    );
  };

  return (
    <div>
      <NavContainer>
        <NavName>JOCELYNE MURPHY</NavName>
        <NavListt></NavListt>
        <Burgg></Burgg>
        <div>{clicked ? <MobileNavListt></MobileNavListt> : null}</div>
      </NavContainer>
    </div>
  );
};

const navSlide = () => {
  const burger = document.querySelector(`.burger`);
  const nav = document.querySelector(`.nav-desktop`);
  const navLinks = document.querySelectorAll(`.nav-desktop li`);

  //toggle nav
  burger.addEventListener(`click`, () => {
    nav.classList.toggle(`nav-active`);

    //animate links
    navLinks.forEach((link, index) => {
      if (link.style.animation) {
        link.style.animation = ``;
      } else {
        link.style.animation = `navLinkFade 0.5s ease forwards ${index / 5 + 0.3}s`;
      }
    });

    //Burger animation
    burger.classList.toggle(`toggle`);
  });
};
export default Header;
