import React from 'react';
import Header from '../components/Header';
import styled from '@emotion/styled';
import {
  PageTitle,
  Subtitle1,
  Subtitle2,
  SubtitleSection,
  BlockQuote,
  Caption,
  P,
  CaseStudy,
  Hyperlink,
  HeroDiv,
  HeroImage,
  Footer,
} from '../styles/global.js';
import about_image from '../assets/projects/about.png';
import Resume_pdf from '../assets/JocelyneMurphyResume_Fall2021.pdf';

const BackgroundImage = styled(`div`)``;
const links = {
  blueprint: (
    <Hyperlink href="https://uwblueprint.org/" target="_blank">
      {` `}
      UW Blueprint
    </Hyperlink>
  ),
  auvik: (
    <Hyperlink href="https://www.auvik.com/" target="_blank">
      {` `}
      Auvik Networks
    </Hyperlink>
  ),
  svpteens: (
    <Hyperlink
      href="https://www.socialventurepartners.org/waterloo-region/news/reflecting-on-the-2019-20-year-for-svp-teens-a-true-step-forward/"
      target="_blank"
    >
      {` `}
      SVP Teens
    </Hyperlink>
  ),
  syde: (
    <Hyperlink href="http://ugradcalendar.uwaterloo.ca/page/ENG-Systems-Design-Engineering" target="_blank">
      {` `}
      Systems Design Engineering{` `}
    </Hyperlink>
  ),
  dimagi: (
    <Hyperlink href="https://www.dimagi.com/" target="_blank">
      {` Dimagi`}
    </Hyperlink>
  ),
  miovision: (
    <Hyperlink href="https://miovision.com/blog/smart-city-challenge-uses-data-to-make-streets-safer" target="_blank">
      {` `}
      Miovision{` `}
    </Hyperlink>
  ),
  contrary: (
    <Hyperlink href="https://contrarycap.com/" target="_blank">
      {` `}
      Contrary Capital{` `}
    </Hyperlink>
  ),
  nerdsnipe: (
    <Hyperlink href="https://www.urbandictionary.com/define.php?term=nerd-snipe" target="_blank">
      nerd snipe
    </Hyperlink>
  ),
  resume: (
    <Hyperlink href={Resume_pdf} target="_blank">
      resume
    </Hyperlink>
  ),

  linkedin: (
    <Hyperlink href="https://www.linkedin.com/in/jmurphyy/" target="_blank">
      Linkedin
    </Hyperlink>
  ),

  gmail: (
    <Hyperlink href="mailto:jocelynemurphy@gmail.com" target="_blank">
      {` jocelynemurphy@gmail.com`}
    </Hyperlink>
  ),
};

const content = {
  bio: ` Nice to meet you!
  `,
};

// function HeroImage(image) {
//   const HeroDiv = styled('div')`
//     display: block;
//     margin-left: auto;
//     margin-right: auto;
//     margin-top: 10vh;
//     height: 30vw;
//     width: 80%;
//     overflow: hidden;
//   `;

//   return (
//     <HeroDiv>
//       <img src={image}></img>
//     </HeroDiv>
//   );
// }

function Currently() {
  return (
    <>
      <Subtitle1> Currently,</Subtitle1>
      <P>
        <ul>
          <li>
            I&apos;m on the job hunt! Looking for software and product internships in Summer 2022 to develop my skills
            and further my understanding of the career paths possible in tech.
          </li>
          <li>
            Just finished as a Software Developer Co-op at {links.miovision}, a technology company that gathers and
            analyses traffic data to make streets safer (Fall 2021).{` `}
          </li>
          {/* <li>
            Volunteering as a project developer at {links.blueprint}! Learning and contributing to software solutions
            that will amplify the impact of the charities we support.
          </li> */}
          <li>
            Scouting Canadian startups as a Venture Partner at {links.contrary}. Super excited to meet with founders and
            dive into the early stage tech scene!
          </li>
          <li>Starting my 2A school term! </li>
        </ul>
      </P>
    </>
  );
}

function Intro() {
  return (
    <>
      <Subtitle1>Hi, I&apos;m Joss!</Subtitle1>
      <P>(you try coming up with a logical nickname for Jocelyne.... Joc? Joce?)</P>
      <P>
        I&apos;m a {links.syde} Student at the University of Waterloo. I&apos;m currently exploring several career paths
        in tech and am looking to dive deeper into fullstack development and product management!
      </P>
    </>
  );
}

function WhatMotivatesMe() {
  return (
    <>
      <Subtitle1>What Motivates Me</Subtitle1>
      <P>
        {` `}
        🧑‍🤝‍🧑 I&apos;m highly collaborative and love working with, learning from, and creating opportunities for others. I
        feel most at home when I&apos;m surrounded with people who love to give back.
      </P>
      <P>
        {` `}
        🏆 I&apos;m most proud of the work I&apos;ve done leading {links.svpteens} and developing at {links.blueprint},
        two organizations where students apply their skills (technical or otherwise) to make a tangible impact on
        Charities and Non Profit Organizations.{` `}
      </P>
      {/* <P>
        {' '}
        🌍 I&apos;ve also recently created an app to diagnose and refer Tuberculosis patients in partnership with
        {links.dimagi}, an international healthcare organization leading the fight against child mortality and the
        COVID-19 pandemic.
      </P> */}
      <P>
        🤓 Finally, nothing beats the thrill of learning something new, whether that be related to software, government
        policy, design, urban planning, history, or weird facts about space. Very succeptible to a good old fashioned
        {` `}
        {links.nerdsnipe}.{` `}
      </P>
    </>
  );
}

function ByTheNumbers() {
  return (
    <>
      <Subtitle1>By the numbers: 💯</Subtitle1>
      <P>🥖 Languages: 2</P>
      <P>🛶 Canoe trips: 8</P>
      <P>🎧 Spotify playlists: 82</P>
      <P>🍓 Yearly strawberry consumption: 1,000+</P>
      <P>🏃🏽‍♀️ Kilometres travelled on foot since lockdown started: 600 and counting 😁</P>
    </>
  );
}

function Connect() {
  return (
    <>
      <Subtitle1>Connect with me! 📱</Subtitle1>
      <P>
        Feel free to download my {links.resume}, connect with me on {links.linkedin}, or send me an email at
        {links.gmail}.
      </P>
      <div style={{ height: `10vh` }}> </div>
    </>
  );
}

function About() {
  return (
    <div>
      <Header></Header>
      <HeroDiv>
        <HeroImage src={about_image}></HeroImage>
      </HeroDiv>
      <CaseStudy>
        <Intro />
        <Currently />
        <WhatMotivatesMe />
        <ByTheNumbers />
        <Connect />
      </CaseStudy>
    </div>
  );
}

export default About;
