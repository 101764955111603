import React from 'react';
import Header from '../components/Header';
import { PageTitle, Subtitle1, A, Button } from '../styles/global.js';
import Cardd from '../components/Card';
import CardddV from '../components/CardddV';

import bpsmallpic from '../assets/projects/BlueprintHero.png';
import pillarsmallpic from '../assets/projects/PillarHero.png';
import fundrisesmallpic from '../assets/projects/FundriseHero.png';
import styled from '@emotion/styled';
import Lightning_pdf from '../assets/lightningproject.pdf';
import shrekbot from '../assets/projects/shrekbot.mp4';
import firefighterpdf from '../assets/projects/Firefighter.pdf';

import YoutubeEmbed from '../components/YoutubeEmbedd';
import firefighter from '../assets/projects/oldwebsite/tech/IMG_E0831.JPG';
import led from '../assets/projects/oldwebsite/tech/ledscreenshot.png';
import sumobot from '../assets/projects/oldwebsite/tech/IMG_E0816.JPG';
import watercooler from '../assets/projects/oldwebsite/coding/watercooler.png';
import zapped from '../assets/projects/oldwebsite/coding/zapped.png';
import website from '../assets/projects/oldwebsite/coding/website.png';
import ledvideo from '../assets/projects/oldwebsite/tech/ledvideo.mp4';
import fundrisepdf from '../assets/casestudy/fundrise/fundrisevisualcasestudy.pdf';
import pillarpdf from '../assets/casestudy/pillar/Pillar-JocelyneMurphy,AnsonYu,JenniferTsai,HimaSheth.pdf';

const content = {
  bio: `Hi, I'm Jocelyne! I'm a software developer, product designer, and
  `,
  blueprint: `Designing UW Blueprint's website to generate more interest in the organization among nonprofit partners and future members. `,
  pillar: `Empowering women to take control of their wealth through a peer-based financial literacy app.`,
  fundrise: `Leveraging design to encourage the adoption of high performance fundraising and team management techniques to help large-scale fundraisers achieve their goals.`,
};

const BackgroundImage = styled(`div`)`
  width: 100vw;
  height: 400px;
  background-size: cover;
  margin-right: 0;
  margin-left: auto;
  padding: 0;
`;

const ProjContainer = styled(`div`)`
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  @media screen and (max-width: 1000px) {
    display: flex;
    flex-direction: column;
  }
`;

const SmallProjContainer = styled(`div`)`
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  @media screen and (max-width: 1000px) {
    display: flex;
    flex-direction: column;
  }
`;

function Hardware() {
  return (
    <>
      <Subtitle1 style={{ marginLeft: `1vw` }}>Hardware & Electronics</Subtitle1>
      <ProjContainer>
        <Cardd
          img={firefighter}
          title="Firefighter Robot"
          description="An autonomous robot that navigates obstacle-ridden mazes to extinguish a hidden flame. "
          date=" Partner Project | September 2019 - January 2020 "
          hreef={firefighterpdf}
          style={{ margin: `-20px` }}
          t1="Project Management"
          t2="Multimeter Troubleshooting"
          t3="CAD"
          cta="Write up & PCB Board Designs > "
        ></Cardd>
        <Cardd
          img={led}
          title="LED Cube"
          hreef={ledvideo}
          description="A 4x4 LED Cube that displays 3D patterns and makes use of pulse width modulation for a dimming effect. "
          date=" Solo Project | February 2019 - April 2019"
          t1="PCB Design"
          t2="Soldering"
          t3="PIC Basic Programming"
          cta="Video Demo > "
        ></Cardd>
        {/* <video preload autoPlay muted playsinline loop>
            <source src={led} type="video/mp4" />
          </video> */}
        <Cardd
          img={sumobot}
          title="Shrek Bot"
          hreef={shrekbot}
          description="A self-driving fighter robot that makes use of infrared sensors to detect opponent bots and push them out of a ring. "
          date=" Partner Project | April 2019 - June 2019"
          t1="CAD"
          t2="PIC BASIC Programming"
          t3="Multimeter Troubleshooting"
          cta="Competition Videos > "
        ></Cardd>
      </ProjContainer>
    </>
  );
}

function Software() {
  return (
    <>
      <Subtitle1 style={{ marginLeft: `1vw` }}>Software</Subtitle1>
      <SmallProjContainer>
        <CardddV
          img={zapped}
          title="Community Fridge"
          hreef="https://github.com/uwblueprint/community-fridge-kw"
          target="_blank"
          description="An open-source scheduling tool to coordinate the donors and volunteers of a local nonprofit that reallocates food waste from local businesses to charities in need"
          date="UW Blueprint | December 2021 "
          tags={[`React`, `Typescript`, `REST`, `PostgreSQL`]}
          cta=" Github > "
        ></CardddV>
        <CardddV
          img={zapped}
          title="Project READ"
          hreef="https://github.com/uwblueprint/project-read-backend"
          target="_blank"
          description="A web application that streamlines outreach and operational processes for family literacy programs."
          date="UW Blueprint | August 2021 "
          tags={[`React`, `Typescript`, `Python`, `Docker`]}
          cta=" Github > "
        ></CardddV>
        <CardddV
          img={zapped}
          title="Zapped!"
          hreef={Lightning_pdf}
          target="_blank"
          description="An animated simulator that models the accumulation of charge in storm clouds by updating the state of cells based on their surroundings."
          date="Solo Project | March 2020 "
          tags={[`Cellular Automata`, `Processing`]}
          cta=" User Instructions > "
        ></CardddV>
        <CardddV
          img={watercooler}
          title="Watercooler"
          hreef="https://devpost.com/software/watercooler-w1z37x"
          target="_blank"
          description="A web app that casually connects university students in a pressure-free environment between study sessions."
          date=" Hack the 6ix  |  August 2020"
          tags={[`Figma`, `HTML/CSS`]}
          cta=" Devpost > "
        ></CardddV>
        <CardddV
          img={website}
          title="Personal Portfolio Website"
          hreef="https://github.com/jossmurphy/jossmurphy.github.io"
          description="The second iteration of my personal website, built using React, Emotionjs, MaterialUI, and hosted on Firebase. "
          date=" Solo Project  |  May 2021"
          tags={[`React`, `EmotionJS`, `MaterialUI`]}
          cta=" Github > "
        ></CardddV>
      </SmallProjContainer>
    </>
  );
}

function Design() {
  return (
    <>
      <Subtitle1 style={{ marginLeft: `1vw` }}>Product Design & UX</Subtitle1>
      <ProjContainer>
        <Cardd
          img={bpsmallpic}
          title="UW Blueprint Website Redesign"
          hreef="/projects/blueprint"
          description={content.blueprint}
          date=" UW Blueprint |  January 2021 - May 2021"
          t3="Information Architecture"
          t2="User Interviews"
          t1="Visual Design"
          cta="Case Study > "
        ></Cardd>
        <Cardd
          img={pillarsmallpic}
          title="Pillar"
          // hreef="/projects/pillar"
          hreef={pillarpdf}
          description={content.pillar}
          date="Hack the Globe |  February 2021"
          t1="Figma"
          t2="Framer"
          t3="Wireframing"
          cta="Research, Business Plan & Onboarding Flow Live Demo > "
        ></Cardd>
        <Cardd
          img={fundrisesmallpic}
          title="Fundrise"
          // hreef="/projects/fundrise"
          hreef={fundrisepdf}
          description={content.fundrise}
          date="Solo Project |  August 2019, December 2020"
          t1="User Testing"
          t2="Wireframing"
          t3="WIX Website Editor"
          cta="Visual Case Study > "
        ></Cardd>
      </ProjContainer>
    </>
  );
}

export default function Projects() {
  return (
    <div>
      <Header></Header>
      <div id="projects" style={{ padding: `100px 12vw` }}>
        <PageTitle style={{ marginLeft: `1vw` }}>Projects</PageTitle>
        <Software />
        <Hardware />
        <Design />

        <Subtitle1 style={{ marginLeft: `1vw` }}>Film & Video Editing</Subtitle1>
        <div style={{ width: `100%`, display: `flex`, flexDirection: `column` }}>
          <YoutubeEmbed embedId="https://www.youtube.com/embed/NaV6BaJ_g6k"></YoutubeEmbed>
          <YoutubeEmbed embedId="https://www.youtube.com/embed/DsoEhyteT-0"></YoutubeEmbed>

          <YoutubeEmbed embedId="https://www.youtube.com/embed/s2dToa1_WvQ"></YoutubeEmbed>
          <YoutubeEmbed embedId="https://www.youtube.com/embed/PCh4vxUT8MU"></YoutubeEmbed>
        </div>
        <center>
          <Button style={{ margin: `50px` }}>
            <A href="/">Return to Home Page</A>
            {` `}
          </Button>
        </center>
      </div>
    </div>
  );
}
